<style scoped>
    @page {
        size: letter portrait;
    }

    @media print {
        body {
            font-size: 12px;
        }

        .page-break {
            page-break-before: always;
            counter-reset: page;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td, th.bordered {
        border: 1px solid #000000;
        text-align: left;
        padding: 8px;
    }

    th.no-border {
        border: none;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    .info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }

    #page-counter {
        display: table-footer-group;
    }

    #page-counter:after {
        counter-increment: page;
        content: ' ' counter(page) ' of ' counter(page);
    }
</style>

<template>
    <div>
        <div class="page-break" :key="idx" v-for="(data, idx) in datas">
            <table class="" style="width:100%">
                <thead>
                    <tr class="">
                        <th colspan="7">
                            <div class="title" style="text-align:center; font-weight:bold;">
                                <p>PICKING LIST PICKUP</p>
                                <p>PT. SINARMAS DISTRIBUSI NUSANTARA</p>
                            </div>
                        </th>
                    </tr>
                    <tr class="">
                        <th class="py-0 w-32" style="text-align:left; white-space: nowrap;">Cabang</th>
                        <th class="py-0 w-1" style="text-align:left;">:</th>
                        <th class="py-0 w-32" style="text-align:left; white-space: nowrap;">{{ data.WarehouseCode }} / {{ data.WarehouseName }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5" style="text-align:left;">Print</th>
                        <th class="py-0 w-1" style="text-align:left;">: {{ data.PrintCounter }}</th>
                    </tr>
                    <tr>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-1"></th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5" style="text-align:left; white-space: nowrap;">Print date</th>
                        <th class="py-0 w-1" style="text-align:left; white-space: nowrap;">: {{ dateNow }}</th>
                    </tr>
                    <tr>
                        <th class="py-0 w-32" style="text-align:left; white-space: nowrap;">Tanggal Shipment</th>
                        <th class="py-0 w-1" style="text-align:left;">:</th>
                        <th class="py-0 w-32" style="text-align:left; white-space: nowrap;">{{ formatDate(data.ShipmentDate) }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5" style="text-align:left; white-space: nowrap;">Print time</th>
                        <th class="py-0 w-1" style="text-align:left; white-space: nowrap;">: {{ timeNow }}</th>
                    </tr>
                    <tr>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-1"></th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5" style="text-align:left;">Page</th>
                        <th class="py-0 w-1" style="text-align:left;"><div id="page-counter">: </div></th>
                        <th class=" py-0 w-5"></th>
                    </tr>
                    <br>
                    <tr>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center; border-bottom:1px solid;">SKU Code</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center; border-bottom:1px solid;" colspan="2">SKU Name</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center; border-bottom:1px solid;">BATCH</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center; border-bottom:1px solid;">ED</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center; border-bottom:1px solid;">QTY</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center; border-bottom:1px solid;">UOM</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="(tr, idxtr) in data.PickingItemConvertionLines">
                        <tr :key="idxtr">
                            <td class="py-1">{{ tr.SkuCode }}</td>
                            <td class="py-1" colspan="2">{{ tr.ItemName }}</td>
                            <td class="py-1">{{ tr.Batch }}</td>
                            <td class="py-1">{{ formatDate(tr.ExpiredDate) }}</td>
                            <td class="py-1">{{ tr.Amount }}</td>
                            <td class="py-1">{{ tr.Unit }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <br>
            <table class="remove_border">
                <tr>
                    <th style="text-align:center;">Dibuat oleh</th>
                    <th style="text-align:center;">Disiapkan oleh</th>
                    <th style="text-align:center;">Diterima oleh</th>
                </tr>
                <tr>
                    <th style="height: 50px;"></th>
                    <th style="height: 50px;"></th>
                    <th style="height: 50px;"></th>
                </tr>
                <tr>
                    <th style="text-align:center;">Admin Logistic</th>
                    <th style="text-align:center;">Picker</th>
                    <th style="text-align:center;">Checker</th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
    name: "PrintPdf",
    data() {
        return {
            id: null,
            doIds: null,
            datas: [],
            dateNow: "Loading ..",
            timeNow: "Loading ..",
        }
    },
    mounted() {
        this.doIds = window.opener.doIds
        if (this.doIds.length) {
            this.print();
        }
    },
    methods: {
        async print() {
            await this.getData()
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(`/api/wms/v1/simple-outbound-processing/picking-list-pickup`, {
                    params:{
                        delivery_order_ids: this.doIds,
                    }
                }
            );
            if (resp.code == 200) {
                this.datas = resp.data.data
                const now = new Date()
                this.dateNow = this.formatDate(now.setHours(now.getHours()+7))
                this.timeNow = this.formatTime(now)
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatTime: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('hh:mm:ss');
            };
        },
    },
}
</script>